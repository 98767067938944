import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { AudioPlayer } from "./AudioPlayer";
import axios from "axios";
import fileDownload from "js-file-download";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;
export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: rgb(0, 244, 0);
  padding: 10px;
  font-family: "dashDigital";
  font-weight: bold;
  color: #000;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonA = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--button-bg);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonB = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--button-bg);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonPS = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--button-bg);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonUnlock = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: purple;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintedWlA, setmintedWlA] = useState(0);
  const [wlA, setwlA] = useState(false);
  const [passwd, setpasswd] = useState("");
  const [whitelistProof, setwhitelistProof] = useState("");
  const [whitelistValid, setwhitelistValid] = useState(false);
  const [mintedWlAPswd, setmintedWlAPswd] = useState(0);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_COSTWLB: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const handleClick = (url, filename) => {
    alert("読むこむので少々お待ちください。");
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const deploy = async () => {
    setClaimingNft(true);
    try {
      let contractFile = require("./data/atd.json");

      const bytecode = contractFile.bytecode;
      const abi = contractFile.abi;
      const atd = new blockchain.web3.eth.Contract(abi);
      atd.options.data = bytecode;
      atd.options.arguments = [blockchain.account];

      console.log(blockchain.account);
      // 7. Create constructor tx
      const atdTx = atd.deploy({
        arguments: [blockchain.account],
        // arguments: [blockchain.account],
      });
      // console.dir(atdTx);
      const estimateGas = Math.round((await atdTx.estimateGas()) * 1.2);
      console.log("estimateGas:" + estimateGas);
      // alert(estimateGas);

      console.log("createTransaction:start");
      // const createTransaction = await blockchain.web3.eth.signTransaction(
      //   {
      //     // from: blockchain.account,
      //     data: atdTx.encodeABI(),
      //     gas: estimateGas,
      //   },
      //   blockchain.account
      // );
      const deployedContract = await atdTx
        .send({
          from: blockchain.account,
          // gas: await atdTx.estimateGas(),
          gas: estimateGas,
        })
        .once("transactionHash", (txhash) => {
          console.log(`Mining deployment transaction ...`);
          console.log(`https://etherscan.io/tx/${txhash}`);
        });
      // The contract is now deployed on chain!
      console.log(`Contract deployed at ${deployedContract.options.address}`);
      console.log(
        `Add NAT_CONTRACT to the.env file to store the contract address: ${deployedContract.options.address}`
      );
      console.log("createTransaction:end");
      // // alert(createTransaction);
      // // 5. Send tx and wait for receipt
      // const createReceipt = await blockchain.web3.eth.sendSignedTransaction(
      //   createTransaction
      // );
      setClaimingNft(false);
    } catch (error) {
      console.log("-----ERR:------------");
      console.error(error);
      console.log("-----ERR:------------");
      setClaimingNft(false);
    }
  };

  const sendEth = async () => {
    const balanceFrom = blockchain.web3.utils.fromWei(
      await blockchain.web3.eth.getBalance(blockchain.account),
      "ether"
    );
    alert(blockchain.account);
    alert(balanceFrom);
    const createTransaction = await blockchain.web3.eth.signTransaction(
      {
        from: blockchain.account,
        gas: 21000,
        to: "0xf8312b5E801CB97cC5ddD7D66a82d5d173D093bc",
        value: blockchain.web3.utils.toWei("0.01", "ether"),
      },
      blockchain.account
    );
    // 5. Send tx and wait for receipt
    const createReceipt = await blockchain.web3.eth.sendSignedTransaction(
      createTransaction
    );
  };

  const claimNFTsA = () => {
    let cost = CONFIG.WEI_COST;
    let amount = mintAmount;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * (amount - 1)); //1枚目フリーミント(ふりっきー)
    let totalGasLimit = String(gasLimit * 1); //個数を1に固定0919(ふりっきー)
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME}ミント中...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .whitelistMint(amount, whitelistProof, passwd)
      // .FreeMintB(1)
      // .psMint(1)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("ミント失敗（泣）もう一回やってみてね！");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `わぁ!${CONFIG.NFT_NAME}ミント成功!!自分のOpen seaで確認してみてね！`
        );
        setClaimingNft(true);
        checkMintedwlA();
        // dispatch(fetchData(blockchain.account));
      });
  };

  const checkWlA = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      console.dir(passwd);
      // let address = blockchain.account;
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");
      let whitelist = require("./data/passwdlists.json");
      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(passwd);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      console.dir("root", root);
      console.dir(root);
      console.dir("proof");
      console.dir(proof);
      if (passwd !== "") {
        blockchain.smartContract.methods
          .isWhitelisted(passwd, proof)
          .call()
          .then((receipt) => {
            setwhitelistProof(proof);
            setwhitelistValid(receipt);
            console.log("whitelistValid/" + whitelistValid);
            dispatch(fetchData(blockchain.account));
            setwlA(receipt);
          });
      }
    }
  };

  const checpswdMinted_A = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .pswdMinted(passwd)
        .call()
        .then((receipt) => {
          setmintedWlAPswd(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkMintedwlA = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .wlMinted(blockchain.account)
        .call()
        .then((receipt) => {
          setmintedWlA(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const changePasswd = (event) => {
    const newVal = event.target.value;
    if (passwd != event.target.value) {
      setpasswd(newVal);
      setpasswd((newVal) => {
        //初回クリック時のpre_countは1
        console.dir(`pass:${newVal}`);
        return newVal;
      });
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 1 - mintedWlAPswd) {
      newMintAmount = 1 - mintedWlAPswd;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    checkMintedwlA();
    checkWlA();
  }, []);

  useEffect(() => {
    getData();
    checkMintedwlA();
    checkWlA();
  }, [blockchain.account]);

  useEffect(() => {
    checkWlA();
    checpswdMinted_A();
  }, [passwd]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/logo512.png" : null}
      >
        <StyledLogo alt={"logo"} src={"/logo512.png"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/logo512.png"} />
          </s.Container>
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              border: "4px var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <></>
            ) : (
              <>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {CONFIG.NETWORK.NAME} networkにウォレット接続
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      コネクト
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <StyledButton
                      disabled={claimingNft ? 1 : 0} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                      onClick={(e) => {
                        e.preventDefault();
                        deploy();
                        getData();
                      }}
                    >
                      {claimingNft ? "実行中。。。" : "デプロイ"}
                    </StyledButton>

                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/logo512.png"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            　　
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            　　
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
